
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.esmp-modal-body {
  font-size: 16px;
}
::v-deep .hm-form__element:first-child {
  margin-top: 0;
}
